<template>
	<v-card max-width="800" class="mx-auto mt-5" rounded="xl">
		<v-card-title>{{ $t('settings.changeLanguage') }}</v-card-title>
		<v-card-text>
			<v-form ref="form" v-model="valid">
				<v-select
					v-model="locale"
					:items="availableLanguages"
					:label="$t('auth.language')"
					:rules="rules.locale"
					required
					item-text="name"
					item-value="key"
					return-object
					outlined
					rounded
					hide-details="auto"
					class="pb-3"
					prepend-inner-icon="mdi-translate"
					style="max-width:400px;"
				/>
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-btn color="primary" rounded @click="callChangeLocale()">
				{{ $t('settings.changeLanguage') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsChangeLocaleForm',
	props: {
		currentLocale: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			valid: false,
			locale: null,
			rules: {
				locale: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableLanguages.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
				]
			}
		}
	},
	computed: {
		...mapGetters({
			availableLanguages: 'data/availableLanguages'
		})
	},
	created() {
		this.fetchAvailableLanguages()
		this.locale = this.currentLocale
	},
	methods: {
		callChangeLocale() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.changeLocale(this.locale)
		},
		...mapActions('data', ['fetchAvailableLanguages']),
		...mapActions('settings', ['changeLocale'])
	}
}
</script>
